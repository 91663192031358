<template>
  <div class="imgBox min-w-1400">
    <!-- <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div> -->
    <div class="message1 max-w-1400">Acuerdo de privacidad</div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <!-- <h1>ข้อตกลงความเป็นส่วนตัว</h1> -->
        <!-- <div class="title-line"></div> -->
        <div class="textBox" style="white-space: pre-wrap">{{text}}</div>

      </div>
    </div>

  </div>
</template>

<script>
  // import importedImg1 from "@/page/finamsas/components/img/4_1.png";

  export default {
    data() {
      return {
        // importedImg1: importedImg1,
        text: `En todo caso, esas entidades estarán igualmente sujetas a las obligaciones de confidencialidad en el manejo de la información con las limitaciones legales impuestas por las leyes aplicables sobre la materia, en la jurisdicción donde ésta se recolecte, archive, procese o transmita.
La información personal no será utilizada o tratada con propósitos diferentes a los aquí indicados.

¿Para qué fines utilizaremos sus datos personales?
Los datos personales que recabamos de manera directa de usted o por medio de un tercero, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
• El cumplimiento de la relación contractual derivado de la solicitud y/o contratación de los Servicios.
• Administrar, operar y dar seguimiento a los Servicios que solicita o contrata.
• Realizar el análisis de la información proporcionada por el Titular, a fin de determinar conforme a las disposiciones legales vigentes el nivel de riesgo, viabilidad de pago, perfil transaccional, y cualquier otra evaluación que sea requerida por la normativa para el otorgamiento de los Servicios.
• Formalizar la relación contractual entre a nosotros y el Titular.
• Incluirlo en nuestra base de datos, integrar expedientes, de manera directa o a través de un tercero.
• Procesar las solicitudes que realice por medio de nuestros Medios Electrónicos.
• Crear su perfil como usuario en la App.
• Consultar y realizar investigaciones crediticias ante sociedades de información crediticia que estime conveniente.
• Ofrecer una ampliación o mejora de los Servicios contratados.
• Informar sobre actualizaciones de Servicios.
• Para el cumplimiento de las obligaciones de a nosotros contenidas en la legislación aplicable.
• Enviar todo tipo de notificaciones a través de cualquier medio para requerir el cumplimiento de sus obligaciones derivadas de la solicitud o el Contrato celebrado entre a nosotros y el Titular.
• En caso de incumplimiento de las obligaciones del Titular, en especial las de pago, para contactar a las referencias otorgadas por el Titular con la finalidad de obtener nuevos datos de contacto del Titular, sin que se lleve a cabo acción o gestión de cobranza con las citadas referencias.
• Llevar a cabo inteligencia comercial, realizar investigaciones de mercado, darle visibilidad a su actividad en nuestros Medios Electrónicos, seguimiento de calidad de nuestros Medios Electrónicos, evaluación y mejora de nuestros Medios Electrónicos.
De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
• Envío de publicidad, telemarketing, mercadotecnia, ofertas, promociones, boletín de noticias y ofertas o eventos especiales que puedan ser de interés del Titular, así como servicios o productos ofrecidos por socios comerciales.
• Mercadotecnia o publicitaria.
• Prospección comercial.
En caso de que no desee que sus datos personales sean tratados para estos fines secundarios, desde este momento usted nos puede comunicar lo anterior a través del siguiente mecanismo:
La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.
Tratamiento de datos sin decisión humana valorativa
Le informamos que, para dar cumplimiento a las finalidades previstas en este Aviso de Privacidad, a nosotros realizará tratamientos de datos personales como parte de un proceso de toma de decisiones sin intervención humana valorativa para el otorgamiento del crédito, en relación con el uso de los servicios, así como para dar cumplimiento a algunas(s) de las finalidades (es) informadas en el presente Aviso de Privacidad.
¿Qué datos personales utilizaremos para estos fines?
Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:
• Datos de identificación
• Datos de contacto
• Datos sobre características físicas
• Datos biométricos
• Datos laborales
• Datos académicos
• Datos patrimoniales y/o financieros
Además de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de privacidad utilizaremos los siguientes datos personales considerados como sensibles, que requieren de especial protección:
• Datos biométricos
Transferencia de Datos Personales
a nosotros no realizará transferencias de datos personales a personas físicas o morales que no pertenezcan a a nosotros o sus empresas filiales o terceros que no participen en los servicios que brinda a nosotros.
El titular acepta que a nosotros compartirá sus Datos Personales con: (i) sus sociedades controladoras, subsidiarias o afiliadas bajo el control común de a nosotros, así como a nuestra sociedad matriz o a cualquier sociedad de nuestro grupo corporativo que opere bajo los mismos procesos y políticas internas, (ii) con autoridades competentes, en caso de ser solicitados, en términos de la legislación aplicable, (iii) con la(s) sociedad(es) de información crediticia con la(s) cual(es) a nosotros haya contratado (iv) sociedades o compañías encargadas de cobranza y recolección de pagos (v) con otras empresas en caso de ser adquiridos, fusionados o cualquier otra operación similar por esa compañía, (vi) con otras sociedades que provean infraestructura y/o servicios para la prestación de servicios de a nosotros (encargados) (vii) terceros incluidos entidades financieras, para cesión o descuento de cartera de crédito, endoso o cualquier otra forma de negociación de la cartera crediticia, (viii) socios comerciales con quien se tengan celebrados contratos para la comercialización de productos y/o servicios en beneficio del Titular, y (ix) en cualquier otro de los supuestos establecidos en el artículo 10 y 37 de la Ley.
Transferencias internacionales
El titular acepta que a nosotros comparta y transfiera sus datos y sean tratados fuera del país, si fuere necesario para las finalidades mencionadas en párrafo anterior. Por lo anterior, es importante que usted esté enterado de que sus datos se encuentran protegidos en virtud de que dichas sociedades, de ser necesario que se remitan sus datos, operarán bajo la misma Política de Protección de Datos Personales y cuentan además con una Política de Protección de Datos y Privacidad.
a nosotros comunicará a los receptores, el presente Aviso de Privacidad, con el fin de asegurar que su información se utilice en los términos de este.
Ejercicio de Derechos ARCO
¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?
Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio:
A través del correo electrónico.
Con relación al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:
a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?
A través de una copia de su Identificación Oficial (credencial para votar expedida por el INE).
b) ¿Qué información y/o documentación deberá contener la solicitud?
• Nombre y domicilio u otro medio para comunicarle la respuesta a su solicitud, así como los documentos que acrediten su identidad o en su caso, la representación legal.
• Cualquier otro elemento o documento que facilite la localización de los Datos Personales.
• La solicitud deberá ser firmada al final del escrito y rubricada al calce de cada una de las hojas.
• La descripción de manera clara y precisa de los Datos Personales respecto de los cuales se busca ejercer Derechos ARCO, así como el derecho o derechos que se desea ejercer, lo cual podrá hacerse en el texto del correo electrónico o en un documento adjunto escaneado y debidamente firmado al final de este y rubricado al calce de cada una de las hojas.
• En el caso de solicitudes de rectificación de datos personales, el titular deberá indicar, además de lo señalado, las modificaciones a realizarse y aportar la documentación que sustente su petición.
c) ¿En cuántos días le daremos respuesta a su solicitud?
En 20 (veinte) días hábiles
d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?
Por correo electrónico proporcionado por el Titular a través de la App móvil
e) ¿En qué medios se pueden reproducir los datos personales que, en su caso, solicite?
A través de la App móvil.
Revocación del Consentimiento
Usted puede revocar su consentimiento para el uso de sus datos personales
Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
Con relación al procedimiento y requisitos para la revocación de su consentimiento, le informamos lo siguiente:
a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?
A través de una copia de su Identificación Oficial (credencial para votar expedida por el INE), o el documento a través del cual acredite su representación legal en caso de que no sea el Titular.
b) ¿Qué información y/o documentación deberá contener la solicitud?
• Nombre y domicilio u otro medio para comunicarle la respuesta a su solicitud, así como los documentos que acrediten su identidad o en su caso, la representación legal.
• Cualquier otro elemento o documento que facilite la localización de los Datos Personales.
• La solicitud deberá ser firmada al final del escrito y rubricada al calce de cada una de las hojas.
c) ¿En cuántos días le daremos respuesta a su solicitud?
20 (veinte) días hábiles.
d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?
Correo electrónico proporcionado por el Titular a través de la App móvil
Asimismo, usted se podrá inscribir a los siguientes registros, en caso de que no desee obtener publicidad de nuestra parte:
Registro Público de Usuarios, para más información consulte el portal de internet de la CONDUSEF
¿Cómo puede conocer los cambios en este aviso de privacidad?
El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: Página web y App móvil.
El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente:
Los cambios al Aviso de Privacidad se darán a conocer a los Titulares por medio de la página web y la App móvil, el titular podrá expresar su negativa respecto de los cambios enviando un correo electrónico al correo de a nosotros, siempre y cuando las solicitud sea viable, en caso contrario se le dará a conocer dicha situación y los impedimentos legales. En caso de que los cambios requieran el consentimiento expreso del Titular, se le solicitará a través de la App móvil.
Los contactos solo se utilizan para verificar su información y ponerse en contacto con usted en caso de emergencia. La información del préstamo NO será compartida.
En las tasas moratorias existen límites dentro de estas tasas, con el fin de que no superen un determinado porcentaje del importe prestado.`,
      };
    },
  };
</script>

<style scoped>
  .message1 {
    position: absolute;
    width: 100%;
    height: 320px;
    flex-shrink: 0;
    background: #0DA7FF;

    color: #ffffff;
    text-align: center;
    font-family: "SF Pro Display";
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 320px;
    z-index: 100;
  }

  .imgBox {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: "";
  }

  .imgBox1 {
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 1400px;
    background-color: #fff;
  }

  .imgBox2 {
    display: flex;
    justify-content: center;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 20px;
    height: 3700px;
    background-color: "";
    border-radius: 16px;
    margin: -140px auto 150px;
  }

  .imgBox3 {
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 1400px;
    background-color: "";
  }

  img {
    display: block;
  }

  .img1 {
    overflow: hidden;
    width: 100%;
  }

  .img2,
  .img4 {
    overflow: hidden;
  }

  .img3 {
    width: 100%;
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
  }

  .imgItem1 {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }

  .imgItem2 {
    max-width: 100%;
  }

  .imgItem3 {
    width: 100%;
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
  }

  h1 {
    /* margin-top: 50px; */
    margin-left: 490px;
    color: #3F4842;
    font-size: 40px;
    /* border-bottom: 5px solid #04358A; */
    display: inline-block;
  }

  .textBox {
    margin-top: 50px;
    margin-left: 100px;
    margin-right: 100px;
    font-size: 18px;
    color: #333333;
  }

  .imgBox2 {
    transform: scale(90%);
    margin: -150px auto 0;
    margin-top: 80px;
    height: 4000px;
    z-index: 500;
    background-color: #ffffff;
    box-shadow: 0 4px 30px 0 #00000033;
    /* box-shadow:0 6px 14px rgba(0, 0, 0, 0.25); */
  }

  .title-line {
    width: 430px;
    /* background: url(./components/img/Group1.png); */
    /* background-repeat: no-repeat; */
    /* background-position: -150px 10px; */
    /* height: 50px; */
    /* border: 1px solid red; */
    border: 5px solid #0BAF60;
    margin: 0 500px;
  }

  /* .img1{
  margin-top: 50px;
  padding: 0 200px;
} */
</style>