<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/betacreditdinero/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">FINAM S.A.S</span>
        <!-- <div class="titleBox">
          <span>MONG CAI TOKYO ENVIRONMENT AND HEALTH HIGH TECHNOLOGY</span>
          <span>JOINT STOCK COMPANY</span>
        </div> -->
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Página delantera</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Introducción de préstamo</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">Preguntas frecuentes</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Acuerdo de privacidad</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- <div style="height: 130px"></div> -->
  </nav>
</template>

<script>
  export default {
    data() {
      return {
        link1Color: "white",
      };
    },
    methods: {
      isActive(route) {
        return this.$route.path === route;
      },
    },
  };
</script>
<style scoped>
  .navbar {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: "";
    height: 70px;
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  .secondBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* height: 90px; */
    padding: 0 15px;
    background-color: #FFFFFF;
    /* box-shadow: 0px 2px 28px rgba(50, 55, 71, 0.10); */
  }

  .logo {
    font-weight: bold;
    color: black;
    display: flex;
    align-items: center;
  }

  .logoImg {
    width: 60px;
    height: 60px;
    margin-left: 30px;
  }

  .title {
    width: 500px;
    font-size: 18px;
    font-family: DM Sans-Bold;
    font-weight: 600;
    color: #333333;
    /* line-height: 23px; */
    padding-left: 17px;
    /* text-transform: uppercase; */
    height: 24px;
    line-height: 35px;
    /* height: 90px;
  line-height: 90px; */
  }

  .routeText1,
  .routeText2,
  .routeText3,
  .routeText4,
  .routeText5 {
    color: #999999;
    font-family: "SF Pro Display";
    font-size: 18px;
    font-weight: 500;
    /* line-height: 29px; */
    /* text-transform: uppercase; */
  }



  .nav-link1,
  .nav-link2,
  .nav-link3,
  .nav-link4,
  .nav-link5 {
    text-decoration: none;

    margin: 0 auto;
  }

  .nav-links {
    list-style: none;
    display: flex;
    align-items: center;
    /* 垂直居中对齐 */
    justify-content: center;
    /* 水平居中对齐 */
  }

  .nav-links li {
    display: flex;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-left: 4px;
    padding-right: 4px;
    padding: 6px;
    height: 39px;
    /* line-height: 90px; */
  }

  .Li1 {
    width: 180px;
  }

  .Li2 {
    width: 250px;
  }

  .Li3 {
    width: 220px;
  }

  .Li4 {
    width: 220px;
    /* 这个如果给margin-right底部可能会出现边距的问题 */
  }

  .Li5 {
    width: 210px;
    /* 这个如果给margin-right底部可能会出现边距的问题 */
  }

  .active-link {
    background: #FFF;
    background: #FFF;
    /* border-radius: 50px; */
    border-radius: 5px;
  }

  .active-link .routeText1,
  .active-link .routeText2,
  .active-link .routeText3,
  .active-link .routeText4,
  .active-link .routeText5 {
    font-family: "SF Pro Display";
    color: #333333;
    font-weight: 600;
  }

  .title {
    /* border: 1px solid red; */
    padding-left: 70px;
    width: 400px;
  }

  .titleBox {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    font-size: 18px;
    color: #333333;
    font-family: "SF Pro Display";
    font-weight: 700;
    /* border: 1px solid red; */
  }
</style>